import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import DetailSection from "../components/DetailSection"
import NavBar from "../components/NavBar"
import products from "../images/products.jpg"

export default function Products(props) {
    return (
        <Layout>
            <SEO
                title="Products"
            />
            <NavBar />
            <DetailSection
                img={products}
                order="order-first"
                intro="Providing"
                title="All the products you love"
                detail="We stock an array of popular products."
                body="We offer a large selection of popular snacks, beverages, and coffee in the area. On top of popular products, we also offer an array of gluten-free, allergy-free, and vegan options. Wondering if we can stock one of your favorite products? Feel free to ask us!"
                question="Ready to enjoy your favorite snacks and beverages?"
                cta="Contact Us"
                hideContact="hidden"
            />
            <Footer />

        </Layout>
    )
}
